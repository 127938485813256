// NB: to build a cordova app, relative path is needed here
var _require = require('../../src/pages/pagesKeys'),
    STORES_PAGE_KEY = _require.STORES_PAGE_KEY,
    MOBIGEO_PAGE_KEY = _require.MOBIGEO_PAGE_KEY;

var dataConfig = require('./dataConfig');

var projectConfig = require('../../../data/config.js'); // Only export a default object, to be able to
// override properties depending on target environment


module.exports = {
  EVENT_NAME: 'printemps-lyon',
  // Misc UI
  DELAY_BEFORE_CLICK_ACTION: 50,
  // ms
  SHOW_TERMS_OF_USE: true,
  // Terms of use URL
  TOU_LINK: {
    en: 'https://printemps-lyon-data-prod.mobile-spot.com/cgu/cgu_en.html',
    fr: 'https://printemps-lyon-data-prod.mobile-spot.com/cgu/cgu_fr.html',
    zh: 'https://printemps-lyon-data-prod.mobile-spot.com/cgu/cgu_zh.html'
  },
  TOU_LINK_TARGET: '_blank',
  TOU_LINK_OPENININAPPBROWSER: true,
  // App profiles
  SUPPORTED_PROFILES: ['default'],
  DEFAULT_PROFILE: 'default',
  // Routing (in two columns mode, = default page on left column)
  getHomePage: function getHomePage() {
    return {
      pageKey: MOBIGEO_PAGE_KEY,
      props: null
    };
  },
  APP_TITLE_SEPARATOR: ' - ',
  ROUTE_SEPARATOR: '?',
  // Lang
  SUPPORTED_LANGS: ['fr', 'en', 'zh'],
  DEFAULT_LANG: 'en',
  // Backend / Updates
  UPDATE_ENABLED: true,
  BO_SSH_USER: 'www',
  // Mobile-Spot link
  MOBILESPOT_WWW: 'http://www.mobile-spot.com',
  // SHARE:
  DEFAULT_SHARE_IMAGE: '',
  //  on LinkedIn
  LINKEDIN_API_KEY: '',
  //  on Facebook
  FACEBOOK_APP_ID: '',
  WEB_URL: "https://".concat(projectConfig.web.url),
  // In web mode, this fallback url can be used if the user's
  // browser does not meet some requirements (eg IE<11)
  WEB_FALLBACK: '',
  // e.g com.printemps.lyon.dev
  NATIVE_ID: projectConfig.native.id,
  // Note: handling urls depending on environment is welcomed.
  // in that case, declare this variable in config-[env].js
  ADDITIONAL_DEFAULTSRC_CSP: [],

  /**
   * FEATURES
   */
  DOWNLOAD_FAVORITES: {
    FEATURE_ENABLED: false
  },
  APPOINTMENT_REQUEST: {
    FEATURE_ENABLED: false // webservice url is defined per environment

  },
  CONTRIBUTIONS_FEED: {
    FEATURE_ENABLED: false
  },
  FLIGHTS_SCHEDULE: {
    FEATURE_ENABLED: false
  },
  LOGIN: {
    FEATURE_ENABLED: false,
    // webservice url is defined per environment
    forgottenPasswordURL: null,
    forgottenPasswordURLTarget: '_blank',
    createAccountURL: null,
    createAccountURLTarget: '_blank',
    tosURL: null,
    tosURLTarget: '_blank'
  },
  POLL: {
    FEATURE_ENABLED: false
  },
  USER_DATA: {
    FEATURE_ENABLED: false
  },
  NETWORKING: {
    FEATURE_ENABLED: false
  },
  SHARE: {
    FEATURE_ENABLED: true,
    POSITION_SHARE_IMAGE: '',
    // Declare restrictions
    // If current page + current profile are found below,
    // then share button is hidden.
    RESTRICTIONS: [// e.g
      // { pageKey: EXHIBITOR_PAGE_KEY,
      //   for : [ VISITOR_GP_PROFILE ]
      // }
    ]
  },
  IMAGE_UPLOAD: {
    // see https://github.com/blueimp/JavaScript-Load-Image#options
    LOAD_IMAGE_OPTIONS: {
      maxWidth: 900,
      maxHeight: 900,
      contain: false,
      orientation: true,
      canvas: true
    },
    INPUT_ACCEPTED_MIME: 'image/*',
    OUTPUT_MIME: 'image/jpeg',
    OUTPUT_COMPRESSION: 0.92,
    DISPLAY_IMAGE_OPTIONS: {
      maxWidth: 300,
      maxHeight: 300,
      cropMinWidth: 100,
      cropMinHeight: 100,
      cropInitialWidth: 300,
      cropRatio: 0.85
    }
  },
  NOTES: {
    FEATURE_ENABLED: false
  },
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    // webservice url is defined per environment
    // Debouncing value represents the delay between
    // last favorite toggle (add or remove) and server call
    DEBOUNCING: 10 * 1000 // ms

  },
  SOCIAL: {
    FEATURE_ENABLED: false
  },
  EXPORT_EVENT: {
    FEATURE_ENABLED: false
  },
  CODEPUSH: {
    FEATURE_ENABLED: false
  },
  MODAL_PRIVACY_AGREEMENT: {
    ENABLED_PLATFORM_FEATURE: []
  },
  MAP: {
    DEFAULT_MAP_PAGE_KEY: MOBIGEO_PAGE_KEY,
    // default map page key, when there is only one to be choosed like for the feature TwoColumn ( GOOGLE_MAP_PAGE_KEY OR MOBIGEO_PAGE_KEY )
    MOBIGEO: {
      FEATURE_ENABLED: true,
      API_KEY: 'ahd5Ahqu2oof',
      // need to be the same as in data/config.default.js => data.default
      LOG_LEVEL: 'NONE',
      // accepted values: NONE | INFO | DEBUG
      USED_TABLES: [// DATA_TYPE_AIRCRAFTS,
      // DATA_TYPE_BRANDS,
      dataConfig.DATA_TYPE_STORES, dataConfig.DATA_TYPE_EVENTS, dataConfig.DATA_TYPE_EXHIBITORS, dataConfig.DATA_TYPE_ICONS, dataConfig.DATA_TYPE_PLACES, // DATA_TYPE_RESTAURANTS,
      dataConfig.DATA_TYPE_SERVICES, // DATA_TYPE_ANIMATIONS
      dataConfig.DATA_TYPE_HAPPENINGS // also: gtw_paths, paths, polygons, but they are in graph.json
      // which is an asset, updated by complete overriding.
      ],
      AUTO_RELOAD_AT_UPDATE: true
    },
    GOOGLE_MAP: {
      FEATURE_ENABLED: false,
      HAS_GMAP_FILTER_BY_CATEGORIES: false,
      API_KEY: 'AIzaSyA9LJKlxiAIr-DErCo1xaMTHqZ7WWH96QM',
      USED_TABLE: dataConfig.DATA_TYPE_STORE_CATEGORIES,
      PAGE_KEY_RELATED_TO_USED_TABLE: STORES_PAGE_KEY,
      USED_TABLES: [dataConfig.DATA_TYPE_STORE_CATEGORIES, dataConfig.DATA_TYPE_STORES, dataConfig.DATA_TYPE_GMAP_PLACES],
      AUTO_RELOAD_AT_UPDATE: true
    }
  }
  /* iosAppStoreUrl: projectConfig.native.iosAppStoreUrl,
  playStoreUrl: projectConfig.native.playStoreUrl, */

  /* name: projectConfig.native.name.default,
  author: projectConfig.native.author, */

};