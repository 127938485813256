module.exports = require("deepmerge")(require("./config.default.js"), {
  version: {
    codepushDeploymentVersion: {
      iOS: "",
      android: ""
    }
  },
  native: {
    appleTeamId: "2BAP3P29V2",
    name: {
      default: "Printemps Lyon_D"
    },
    id: "com.printemps.lyon.dev",
    appleAppId: "1605952143"
  },
  codePush: {
    android: {
      APP_SECRET: "",
      CodePushDeploymentKey: ""
    },
    iOS: {
      APP_SECRET: "",
      CodePushDeploymentKey: ""
    }
  },
  web: {
    url: "printemps-lyon-dev.mobile-spot.com",
    deploy: {
      host: "kim1",
      user: "www",
      path: "/home/www/printemps/printemps-lyon"
    }
  }
});