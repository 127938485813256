module.exports = {
  name: "printemps-lyon",
  client: "Printemps Lyon",
  codifName: 'M446',
  description: "Projet MobiGeo pour l'application Printemps Lyon",
  pluginId: "mobigeo-plugin-ph",
  validLangs: ["en", "fr", "zh"],
  version: {
    mainVersion: "1.0.0",
    buildVersion: {
      iOS: "6",
      android: "6",
      web: "6"
    }
  },
  native: {
    author: "Mobile-Spot",
    backgroundColor: "0xffffff",
    // iOS
    //iosAppStoreUrl: "",
    iosBuildTarget: "11.0",
    // Android
    // 'androidapp-id': 'TODO', // unused?
    //playStoreUrl: "",
    androidFadeSplashScreenDuration: 750,
    androidTargetSdkVersion: 30,
    androidSigning: {
      keyAlias: "Printemps-Lyon",
      keyStorePassword: "ohCee8too7raifee"
    },
    // Web app to wrap in the cordova app ('ionic/www', 'simple', 'test', 'demo', 'app-react' - see cordova/testapps/)
    app: "app-react"
  },
  // List the folders of the data bundles to include for this project
  data: {
    default: "ahd5Ahqu2oof"
  },
  // 'location', 'location.geogroup', 'location.geopush', 'map.route', 'assets.update', 'poi.aroundme', 'favorites'
  features: [],
  location: {
    registering: {
      location: false,
      geofencing: false
    },
    mapping: {}
  },
  undeliveredFolders: ["source", "exports"],
  // which additional repos need to be cloned
  relatedRepos: [{
    src: 'git@bitbucket.org:mobilespotdev/cordova.git',
    postCommands: ['cd cordova && npm install']
  }, {
    src: 'git@bitbucket.org:mobilespotdev/mobigeo.git',
    postCommands: ['cd mobigeo && npm install']
  }, {
    src: 'git@bitbucket.org:mobilespotdev/app-react.git'
  }, {
    src: 'git@bitbucket.org:mobilespotdev/data-printemps-lyon.git',
    dest: 'app-react/data',
    postCommands: ['cd app-react && yarn', 'cd app-react && npm run set-env dev', 'cd app-react && npm run download-assets', 'cd app-react && npm run download-data-files', 'cd app-react && npm run update-mobigeo']
  }],
  cordova: true,
  crypto: false,
  // @see doc on google drive: R&D/Migration WK webview: https://docs.google.com/spreadsheets/d/1sIfm3LgBq1Zgp7LH2-mKSD1vrL6VphNR1mkiHZ73zHM/edit?usp=drive_web&ouid=101003723454836243359
  // @see cordova/bin/addIosCustomUrlScheme.js
  // @see app-react/src/core/data-and-assets/FileSystemHelper.js `getPath`
  // @see app-react/src/core/util/FetchHelper.js
  customFileUrlScheme: "msfile",
  cordova_plugins: {
    mobigeo_plugin_dependencies: [],
    app_dependencies: [{
      id: "../src-app-plugins/cordova-plugin-mobilespot-app",
      variable: "HOST",
      varValue: "web.url"
    }, {
      id: "../src-app-plugins/cordova-plugin-mobilespot-badge-scan"
    }, {
      id: "cordova-plugin-file"
    }, {
      id: "cordova-plugin-file-opener2",
      variable: "ANDROID_SUPPORT_V4_VERSION",
      rawVarValue: "27.+"
    }]
  },
  geoGroup: {},
  web: {}
};