import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  DATA_TYPE_EVENTS,
  DATA_TYPE_GMAP_PLACES,
  getSpeakerTitle,
  DATA_TYPE_EXHIBITORS,
  // getSpeakerSubtitle,
} from 'data/config/dataConfig';

import { GMAP_PLACES_PAGE_KEY } from 'src/pages/pagesKeys';

// import DetailCountry      from 'src/components/detail-country/DetailCountry';
// import DetailEvents       from 'src/components/detail-events/DetailEvents';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import DetailSocialRaw from 'src/components/detail-social/DetailSocialRaw';
import DetailExhibitor from 'src/components/detail-exhibitor/DetailExhibitor';
// import Description        from 'src/components/fiche/Description';
import WebsiteRow from 'src/components/fiche/WebsiteRow';
import ShareButton from 'src/components/share-button/ShareButton';
import NoteButton from 'src/components/notes/NoteButton';
import List from 'src/components/list/List';
// import PhoneRow           from 'src/components/fiche/PhoneRow';
// import EmailRow           from 'src/components/fiche/EmailRow';
// import ShareButton        from 'src/components/share-button/ShareButton';
import ContactButton from 'src/components/contact-button/ContactButton';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

import { isCordovaContext } from 'src/core/util/browser';

import '../common-fiche.scss';
import './GmapPlacesContent.scss';

class GmapPlacesContent extends PureComponent {
  setScrollableContentEl = (el) => {
    this.contentContainerEl = el;
  };
  scrollTo = (value) => {
    if (this.contentContainerEl) {
      this.contentContainerEl.scrollTop = value;
    }
  };

  onClickOnFavIcon = () => {
    this.props.actions.toggleFavorite(
      this.props.item.id,
      DATA_TYPE_GMAP_PLACES,
      this.props.isFavorite
    );
  };

  showEvents = () => {
    this.props.actions.navigateToItems(
      this.props.item.references[DATA_TYPE_EVENTS],
      DATA_TYPE_EVENTS,
      this.props.item.id,
      DATA_TYPE_GMAP_PLACES
    );
  };

  render() {
    const title = this.props.item.title;
    return (
      <div className="fiche speaker content-font content-below-apptoolbar">
        {/* title */}
        <DetailFixedTitle
          title={title}
          dataType={DATA_TYPE_GMAP_PLACES}
          isFav={this.props.isFavorite}
          onFavClick={this.onClickOnFavIcon}
          labels={this.props.labels}
        >
          <NoteButton
            itemTitle={title}
            itemId={this.props.item.id}
            dataType={DATA_TYPE_GMAP_PLACES}
            hasNote={this.props.hasNote}
            labels={this.props.labels}
            actions={this.props.actions}
          />
        </DetailFixedTitle>

        <div className="content" ref={this.setScrollableContentEl}>
          <div className="all-informations content-font">
            <div className="fiche-row1">
              <div className="fiche-row1-col2 s">
                <div
                  className="fiche-subtitle"
                  dangerouslySetInnerHTML={{ __html: this.props.item.description }}
                />

                <DetailSocialRaw data={this.props.item.lump.social} actions={this.props.actions} />
              </div>
            </div>

            <div className="fiche-row2">
              {/*
                <WebsiteRow website={this.props.item.website} actions={this.props.actions} />
                <PhoneRow phone={this.props.item.phone} />
                <EmailRow email={this.props.item.email} />
              */}

              {this.props.item.address && (
                <div className="free-row">
                  <div className="prop-img">
                    <div className="prop-left">
                      <div className="fiche-contact-icon">
                        <span className="fa fa-university" />
                      </div>
                    </div>
                    <div
                      className="prop-right"
                      key={this.props.item.id}
                      className={isCordovaContext() ? "clickable" : ""}
                      onClick={() => {
                        if (isCordovaContext()) {
                          const POI = {
                            id: this.props.item.id,
                            originalId: this.props.item.originalId,
                            type: 'gmapplaces',
                            placeId: this.props.item.id,
                          };
                          actions.showOnePoiOnGoogleMap(POI);
                        }
                      }}
                    >
                      <span className="colored-link">{this.props.item.address}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <ContactButton
              participant={this.props.item.references.participant}
              labels={this.props.labels}
            />

            {/* exhibitors */}
            {Array.isArray(this.props.item.references.exhibitors) &&
              this.props.item.references.exhibitors.length > 0 && (
                <div>
                  <div className="fiche-separator">{this.props.labels.data.exhibitors.plural}</div>
                  <List
                    items={this.props.item.references.exhibitors}
                    dataType={DATA_TYPE_EXHIBITORS}
                    actions={this.props.actions}
                    labels={this.props.labels}
                    isFastAndUgly={false}
                    displayFavorites={false}
                    pageKey={GMAP_PLACES_PAGE_KEY}
                  />
                </div>
              )}
            {/* exhibitor
              <DetailExhibitor
                exhibitor={exhibitor}
                actions={this.props.actions}
                labels={this.props.labels}
              />
              */}
          </div>
        </div>
      </div>
    );
  }
}

GmapPlacesContent.propTypes = {
  item: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isFavorite: PropTypes.bool,
  isLogoFullWidth: PropTypes.bool,
  // Common
  queryString: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default GmapPlacesContent;
