var defaultConf = require('./config-default');

module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: 'printemps-lyon-data-dev.mobile-spot.com/',
  // no trailing '/'
  BO_PATH: '/home/www/printemps/printemps-lyon/printemps-lyon-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '/home/www/printemps/printemps-lyon/printemps-lyon-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-44',
  projectId: 217901982433,
  // GCM/FCM send id
  appId: '0A4B3-E297E',
  // pushwoosh appid
  CODEPUSH: {
    FEATURE_ENABLED: false
  }
});