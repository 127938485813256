import _defineProperty from "/Users/mohamedbenkhedher/Documents/react/mspot-printemps-lyon/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _conf;

import { DATA_TYPE_BRANDS, DATA_TYPE_BRAND_CATEGORIES, DATA_TYPE_RESTAURANTS, DATA_TYPE_MONUMENTS, DATA_TYPE_RESTAURANT_CATEGORIES, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_STORE_CATEGORIES, DATA_TYPE_STORES } from './dataConfig';
import { SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';
import { SEARCH_TYPES } from 'src/core/search/Search'; // Perform search when user has typed at least n characters

export var SEARCH_MIN_CHARS = 2; //export const SEARCH_HIDE_LIST_IF_TOTAL_ABOVE = 10;

/**
 * Data types to look through to find POI on map or itinerary start/destination
 * @type {Array}
 */

export var DATATYPES_WITH_PLACES = [DATA_TYPE_BRANDS, DATA_TYPE_RESTAURANTS, DATA_TYPE_MONUMENTS, DATA_TYPE_SERVICES];
export function getSearchType(pageKey, value) {
  if (pageKey !== SEARCH_PAGE_KEY) {
    if (value.length === 1) {
      return SEARCH_TYPES.STARTS_WITH;
    }

    if (value.length === 2) {
      return SEARCH_TYPES.WORD_STARTS_WITH;
    }
  }
} // Common 'title'

var getTitleAttribute = function getTitleAttribute(item) {
  return item.title;
};
/**
 * Expose:
 *  - data types
 *  - and functions returning the value
 * on which search is performed
 */


var conf = (_conf = {}, _defineProperty(_conf, DATA_TYPE_BRANDS, getTitleAttribute), _defineProperty(_conf, DATA_TYPE_BRAND_CATEGORIES, getTitleAttribute), _defineProperty(_conf, DATA_TYPE_RESTAURANTS, getTitleAttribute), _defineProperty(_conf, DATA_TYPE_MONUMENTS, getTitleAttribute), _defineProperty(_conf, DATA_TYPE_RESTAURANT_CATEGORIES, getTitleAttribute), _defineProperty(_conf, DATA_TYPE_SERVICES, getTitleAttribute), _defineProperty(_conf, DATA_TYPE_SERVICE_CATEGORIES, getTitleAttribute), _defineProperty(_conf, DATA_TYPE_STORE_CATEGORIES, getTitleAttribute), _defineProperty(_conf, DATA_TYPE_STORES, getTitleAttribute), _conf);
export var get = function get(profile) {
  return conf;
};